import React from "react"
import PreviewPostPage from "../../components/preview-page"
import Template from "../../templates/page"

const Page = () => (
  <PreviewPostPage
    template={Template}
    mainDataKey="currentPost"
    postType="pages" />
)

export default Page
